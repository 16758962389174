import "lite-youtube-embed/src/lite-yt-embed.css";
import "lite-youtube-embed/src/lite-yt-embed.js";
import MicroModal from "micromodal";
import "../css/micromodal.css";

MicroModal.init({ awaitCloseAnimation: true });

$(function () {
  const $playableTargets = $(".video-target");

  $playableTargets.each(function (index, ele) {
    let videoId = $(ele).attr("data-video-url");
    const $liteYoutubeElement = document.querySelector(
      `#video-modal-${videoId} lite-youtube`
    );
    
    $(ele).on("click", async function () {
      async function onShow() {
        setTimeout(async () => {
          const player = await $liteYoutubeElement.getYTPlayer();
          if (player) {
            player.playVideo();
          }
        }, 0);
      }

      function onClose() {
        setTimeout(async () => {
          const player = await $liteYoutubeElement.getYTPlayer();
          player.pauseVideo();
        }, 0);
      }

      MicroModal.show(`video-modal-${videoId}`, {
        onShow: onShow,
        onClose: onClose,
      });
    });
  });
});
