import Alpine from "alpinejs";
import tippy from "tippy.js";
import "../css/base.css";
import 'flowbite';
import { sendWhatsappMessage } from "./shared";

tippy("[data-tippy-content]", {
  theme: "light",
  allowHTML: true,
});

window.Alpine = Alpine;
Alpine.start();

$(function () {
  const $toggles = $(".faq-summary");

  $toggles.each(function (index, ele) {
    $(ele).on("click", function () {
      const $content = $(".faq-content").eq(index);
      $content.slideToggle("fast");
    });
  });
});

window.sendWhatsappMessage = sendWhatsappMessage;
