import Alpine from "alpinejs";
import tippy from "tippy.js";
import "../css/style.css";
import "../css/info.css";
import { scrollTo } from "./shared";
import "./video-handling";
import 'flowbite';

tippy("[data-tippy-content]", {
  theme: "light",
  allowHTML: true,
});

window.Alpine = Alpine;
Alpine.start();

document.querySelector(".back-to-top").onclick = () => {
  scrollTo(document.documentElement);
};

$(function () {
  const $toggles = $(".faq-summary");

  $toggles.each(function (index, ele) {
    $(ele).on("click", function () {
      const $content = $(".faq-content").eq(index);
      $content.slideToggle("fast");
    });
  });
});

function sendWhatsappMessage(
  message = "Hola, tengo preguntas sobre la aplicación MiDespacho."
) {
  const phoneNumber = "525554560150";
  const urlWhatsApp = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  // Abre la URL en una nueva ventana o pestaña
  window.open(urlWhatsApp, "_blank");
}

window.sendWhatsappMessage = sendWhatsappMessage;
